import Header from "./Header";
import { PrintClient, ScenarioSettings } from "src/types";
import CompositeTable from "./CompositeTable";

const Composite = ({
  scenario,
  client,
}: {
  scenario: ScenarioSettings;
  client: PrintClient;
}) => {
  return (
    <div className="mx-10 flex justify-center flex-col pt-6">
      <Header client={client} scenario={scenario} />
      <div className="text-2xl mx-auto mb-5">Composite</div>
      <CompositeTable scenario={scenario} client={client} />
    </div>
  );
};

export default Composite;
