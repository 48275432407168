import calculate from "src/calculator/calculate";
import { basicTitle } from "src/calculator/title";
import { Client, Income, ScenarioSettings, SelectedColumn } from "src/types";
import {
  getTaxRate,
  printNumber,
  selectedTaxColors,
  splitDate,
  yearRange,
} from "src/utils";
import MapTable, { IncomeTooltip } from "./MapTable";
import LongevityTooltip from "./LongevityTooltip";

interface Props {
  scenario: ScenarioSettings;
  client: Client;
  selectedYear?: number;
  selectedColumn?: SelectedColumn;
  setSelectedColumn?: any;
  setSelectedYear?: any;
  print?: boolean;
}
const SourceTable = ({
  scenario,
  client,
  setSelectedYear,
  selectedYear,
  setSelectedColumn,
  selectedColumn,
  print,
}: Props) => {
  const currentYear = new Date().getFullYear();
  const incomes = scenario.incomes.filter((income) => income.enabled);

  const groupedIncomesByTaxType = incomes.reduce((acc, income) => {
    const key = income.type;
    if (!acc[key]) acc[key] = [];
    acc[key].push(income);
    return acc;
  }, {} as any);
  const divisionFactor =
    client.liveSettings.monthlyYearly === "monthly" ? 12 : 1;

  const data = yearRange(
    currentYear,
    currentYear + client.liveSettings.maxYearsShown - 1,
  ).map((line) => {
    const calculateOne = (income: Income, currentYear: number) => {
      const result = calculate({
        people: client.people,
        income,
        currentYear,
        scenario,
      });

      return {
        ...result,
        amount: result.amount / divisionFactor,
      };
    };

    const income = (type: string) =>
      incomes
        .filter((income) => income.enabled && income.type === type)
        .map((income) => calculateOne(income, line).amount)
        .filter((t) => typeof t === "number")
        .reduce((a, b) => a + b, 0);

    const taxRate = getTaxRate(client, scenario, line);

    return [
      {
        value: <div className="font-medium text-black">{line}</div>,
        key: "year",
      },
      {
        value: (
          <LongevityTooltip client={client} currentYear={line}>
            <div className="font-medium text-black w-6">
              {scenario.people
                .map((p) => line - splitDate(p.birthday).year)
                .join("/")}
            </div>
          </LongevityTooltip>
        ),
        key: "age",
      },
      ...Object.keys(groupedIncomesByTaxType).map((key) => {
        const selectedIncomes = incomes.filter(
          (income) => income.enabled && income.type === key,
        );

        return {
          value: (
            <div
              className={`text-[#475467] ${selectedYear === line ? selectedTaxColors[key] : ""}`}
            >
              {printNumber(income(key) * (1 - taxRate))}
            </div>
          ),
          key,
          tooltip:
            income(key) > 0 ? (
              <IncomeTooltip
                client={client}
                selectedIncomes={selectedIncomes}
                scenario={scenario}
                year={line}
              />
            ) : undefined,
        };
      }),
      {
        value: (
          <div className={`text-black font-medium `}>
            {printNumber(
              incomes
                .map((income) => calculateOne(income, line).amount)
                .filter((t) => typeof t === "number")
                .reduce((a: any, b: any) => a + b, 0) *
              (1 - taxRate),
            )}
          </div>
        ),
        key: "total",
        tooltip: incomes.length ? (
          <IncomeTooltip
            client={client}
            selectedIncomes={incomes.sort((a, b) =>
              a.type.localeCompare(b.type),
            )}
            scenario={scenario}
            year={line}
          />
        ) : undefined,
      },
    ];
  });

  return (
    <MapTable
      client={client}
      selectedYear={selectedYear}
      selectedColumn={selectedColumn}
      setSelectedColumn={setSelectedColumn}
      setSelectedYear={setSelectedYear}
      print={print}
      columns={[
        { name: "Year", key: "year" },
        { name: "Age", key: "age" },
        ...Object.keys(groupedIncomesByTaxType).map((key) => ({
          key,
          name: basicTitle(key),
        })),

        { name: "Total", key: "total" },
      ]}
      data={data}
    />
  );
};

export default SourceTable;
