import { useParams, useSearchParams } from "react-router-dom";
import { IncomeProvider } from "src/hooks/useData";
import { useEffect, useState } from "react";
import { getPrintClient } from "../services/client";
import Report from "../components/Report/Report";

const PrintPage = () => {
  const [client, setClient] = useState(null as any);
  const { id, scenarioId } = useParams();
  const [searchParams, _] = useSearchParams();
  useEffect(() => {
    if (id)
      getPrintClient(id).then((data) => {
        setClient(data);
      });
  }, [id, scenarioId]);

  if (!client || !client.userdata) return <div>Loading...</div>;
  const scenario = client?.scenarios?.find((s: any) => s.id == scenarioId);
  const scenarioData =
    scenarioId == "-1"
      ? {
        ...client.liveSettings,
        incomes: client.incomes,
        people: client.people,
        spending: client.spending,
        name: "Live",
      }
      : {
        ...scenario,
        mapType: client.liveSettings.mapType,
        inflationType: client.liveSettings.inflationType,
        monthlyYearly: client.liveSettings.monthlyYearly,
        chartType: client.liveSettings.chartType,
        maxYearsShown: client.liveSettings.maxYearsShown,
        taxType: client.liveSettings.taxType,
      };

  if (!scenarioData) return <div>Loading...</div>;

  return (
    <IncomeProvider data={client as any} setLocal={() => { }}>
      <div className="bg-white">
        <Report
          client={client}
          scenario={scenarioData}
          page={JSON.parse(searchParams.get("page") || "{}")}
        />
      </div>
    </IncomeProvider>
  );
};
export default PrintPage;
