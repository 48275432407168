import { EmploymentIncome } from "src/types";
import { moyrToAnnual, splitDate } from "../utils";
import { CalculationInfo } from "./types";
import { adjustForIncrease, adjustForInflation, isDead } from "./utils";
import { startEndToYear } from "src/components/Info/StartEndSelect";

const calculate = (info: CalculationInfo<EmploymentIncome>) => {
  const startYear = new Date().getFullYear();
  const { people, income, currentYear } = {
    ...info,
    income: { ...info.income },
  };
  const person = people[income.personId];
  const { year: birthYear } = splitDate(person.birthday);
  const age = currentYear - birthYear;
  const retirementAgeYear =
    (startEndToYear(income.end, info.people, info.scenario) || 3000) -
    birthYear;
  console.log("retirementAgeYear", retirementAgeYear, income.end);

  const startYearr = startEndToYear(income.start, info.people, info.scenario);
  const startAge = startYearr ? startYearr - birthYear : 0;

  if (
    startAge > age ||
    isDead(info, income.personId) ||
    (age > retirementAgeYear && retirementAgeYear != 0)
  ) {
    return 0;
  }

  const annualIncome = income.income
    ? moyrToAnnual(income.income)
    : income.annualIncome;

  let baseAmount = adjustForIncrease(
    info,
    annualIncome,
    Math.max(birthYear + startAge, startYear),
  );

  baseAmount = adjustForInflation(
    info,
    baseAmount,
    Math.max(birthYear + startAge, startYear),
  );

  if (startAge === age) {
    return (baseAmount * income.firstYearProratePercent) / 100;
  }

  if (retirementAgeYear == age) {
    return baseAmount * (income.retirementAgeMonth / 12);
  }
  return baseAmount;
};

export const calculateEmploymentIncome = (
  info: CalculationInfo<EmploymentIncome>,
) => {
  return { amount: calculate(info), note: "" };
};
