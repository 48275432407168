import { Paydown } from "src/types";
import { CalculationInfo } from "./types";
import { adjustForInflation, isDead } from "./utils";
import { startEndToYear } from "src/components/Info/StartEndSelect";

export const calculate = (info: CalculationInfo<Paydown>) => {
  const startYear = new Date().getFullYear();
  const { income, currentYear } = {
    ...info,
    income: { ...info.income },
  };

  const newStartYear =
    startEndToYear(income.start, info.people, info.scenario) || startYear;
  if (info.income.personId !== -1 && isDead(info, info.income.personId))
    return 0;
  if (
    newStartYear > currentYear ||
    newStartYear + income.length <= currentYear
  ) {
    return 0;
  }
  const interest = income.interestRate / 100;
  let amount =
    (income.total * interest) / (1 - Math.pow(1 + interest, -income.length));

  if (income.paymentInYear === "beginning") {
    amount = amount / (1 + interest);
  }

  amount = adjustForInflation(info, amount, startYear);

  return amount;
};

export const calculatePaydown = (info: CalculationInfo<Paydown>) => {
  return { amount: calculate(info), note: "" };
};
