const MultiToggle = ({
  label,
  value,
  options,
  setValue,
  disabled,
  vertical = true,
}: any) => {
  return (
    <div
      className={`w-full  font-medium flex ${vertical ? "flex-col" : "flex-row items-center gap-2"}`}
    >
      <label className={`text-sm text-[#344054] ${vertical ? "w-36" : ""} `}>
        {label}
      </label>
      <div className={`flex ${vertical ? "mt-[3px]" : ""} border-collapse`}>
        {options.map((item: any, i: any) => (
          <button
            key={item}
            className={`${i == 0 ? "rounded-l-md" : ""} ${i == options.length - 1 ? "rounded-r-md ml-[-1px]" : ""} text-sm flex-1 py-[6px] ${
              value === item
                ? "bg-main-orange text-white"
                : "bg-gray-200 text-[#555860]"
            } ${vertical ? "" : "w-full"}`}
            onClick={() => !disabled && setValue(item)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MultiToggle;
