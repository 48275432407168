import Select from "../Inputs/Select";
import Input from "../Inputs/Input";
import IncomeYearlyIncrease from "./IncomeYearlyIncrease";
import { migrateOtherIncome } from "src/calculator/other-income";
import { OtherIncome, Person, ScenarioSettings } from "src/types";
import StartEndSelect from "./StartEndSelect";

interface Props {
  annuity: OtherIncome;
  people: Person[];
  setIncome: (income: OtherIncome) => void;
  settings: ScenarioSettings;
}

const BasicAnnuity = ({
  people,
  annuity: pension,
  setIncome,
  settings,
}: Props) => {
  const options = [...people] as any[];
  if (people.length == 2) {
    options.push({ name: "Joint", id: -1 });
  }
  const newAmount = migrateOtherIncome(pension);

  return (
    <>
      <div className="flex flex-col gap-4 justify-start">
        {people.length > 1 && (
          <Select
            width="!w-64"
            options={options}
            selected={
              pension.personId == -1
                ? { name: "Joint", id: -1 }
                : people[pension.personId]
            }
            setSelected={(i) => setIncome({ ...pension, personId: i.id })}
            label="Person"
          />
        )}
        <Input
          label="Title"
          subtype="text"
          width="!w-64"
          size="lg"
          value={pension.name}
          setValue={(name) => setIncome({ ...pension, name })}
        />
        <Input
          label="Amount"
          subtype="mo/yr"
          width="!w-64"
          size="lg"
          value={newAmount}
          setValue={(name) => setIncome({ ...pension, newAmount: name })}
        />
        {/*
<Input
          label="Income Amount"
          subtype="money"
          size="lg"
          value={pension.amount}
          setValue={(name) => setIncome({ ...pension, amount: name })}
        />
        <Select
          options={[
            { name: "Monthly", id: "monthly" },
            { name: "Quarterly", id: "quarterly" },
            { name: "Semi-Annually", id: "semi-annually" },
            { name: "Annually", id: "annually" },
          ]}
          selected={{
            name: (pension.frequency as any).capitalize(),
            id: pension.frequency,
          }}
          setSelected={(i) => setIncome({ ...pension, frequency: i.id })}
          label="Income Frequency"
        />*/}
        <StartEndSelect
          label="Start"
          shownOptions={[
            ["already-receiving"],
            ["calendar-year"],
            ["#-of-years"],
            ...people.map((p) => ["age", p.id] as any),
            ...people.map((p) => ["retirement", p.id] as any),
            ...people.map((p) => ["death", p.id] as any),
          ]}
          settings={settings}
          value={pension.start}
          setValue={(start) => setIncome({ ...pension, start })}
        />
        <Input
          label="First-Year Prorate"
          subtype="percent"
          size="income"
          tooltip="Percentage of the income you will receive in the first year"
          value={pension.firstYearProRatePercent}
          setValue={(name) =>
            setIncome({
              ...pension,
              firstYearProRatePercent: name,
            })
          }
        />
        <StartEndSelect
          label="End"
          shownOptions={[
            ...people.map((p) => ["age", p.id] as any),
            ["calendar-year"],
            ...people.map((p) => ["retirement", p.id] as any),
            ...people.map((p) => ["death", p.id] as any),
          ]}
          settings={settings}
          value={pension.end}
          setValue={(end) => setIncome({ ...pension, end })}
        />
        {people.length > 1 && pension.personId != -1 && (
          <Input
            label="Survivor"
            size="income"
            subtype="percent"
            width="!w-64"
            tooltip="Percentage of the income that the survivor will receive"
            value={pension.survivorPercent}
            setValue={(name) =>
              setIncome({ ...pension, survivorPercent: name })
            }
          />
        )}
        <IncomeYearlyIncrease
          labels={false}
          increase={
            pension.yearlyIncrease || {
              type: "custom",
              percent: pension.yearlyIncreasePercent,
            }
          }
          setYearlyIncrease={(yearlyIncrease) =>
            setIncome({ ...pension, yearlyIncrease })
          }
        />
        <Select
          options={[
            { name: "Taxable", id: "Taxable" },
            { name: "Tax-Deferred", id: "Tax-Deferred" },
            { name: "Tax-Free", id: "Tax-Free" },
          ]}
          selected={{
            name: pension.taxType,
            id: pension.taxType,
          }}
          setSelected={(i) => setIncome({ ...pension, taxType: i.id })}
          label="Tax Status"
          width="!w-64"
        />
      </div>
    </>
  );
};

export default BasicAnnuity;
