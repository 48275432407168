import { useState } from "react";

import { useInfo } from "src/hooks/useData";
import Layout from "src/components/Layout";
import { useFullscreen } from "src/hooks/useFullScreen";
import Scenarios from "./Scenarios";

import Map from "./Map";
import IncomeSection from "./IncomeSection";
import { Income, ScenarioSettings } from "src/types";

const MapTab = ({ name, active, setActive }: any) => {
  return (
    <div
      className={`${active ? "bg-main-orange text-white" : "bg-white"} py-2 flex items-center justify-center w-64 cursor-pointer rounded-md shadow-md border`}
      onClick={setActive}
    >
      {name}
    </div>
  );
};

const Summary = () => {
  const [tab, setTab] = useState(-1);
  const [mapTab, setMapTab] = useState("income");
  const { data, setField, storeScenarios } = useInfo();
  const { isFullscreen } = useFullscreen();
  const scenarios = data.scenarios;

  const liveSettings = {
    ...data.liveSettings,
    id: -1,
    people: data.people,
    incomes: data.incomes,
    spending: data.spending,
  };

  const init =
    tab == -1 ? liveSettings : (scenarios.find(({ id }) => id === tab) as any);

  const settings = {
    ...init,
    inflationType: data.liveSettings.inflationType,
    mapType: data.liveSettings.mapType,
    monthlyYearly: data.liveSettings.monthlyYearly,
    chartType: data.liveSettings.chartType,
    maxYearsShown: data.liveSettings.maxYearsShown,
    taxType: data.liveSettings.taxType,
    showTaxType: data.liveSettings.showTaxType,
  };
  console.log("sett", settings);

  const setSettings = (settings: ScenarioSettings) => {
    if (tab === -1) {
      setField("liveSettings")({ ...data.liveSettings, ...settings });
    } else {
      storeScenarios(
        scenarios.map((item) =>
          item.id === tab ? { ...item, ...settings } : item,
        ),
      );
    }
  };

  const updateIncomes = (incomes: Income[]) => {
    if (tab === -1) {
      setField("incomes")(incomes);
    } else {
      storeScenarios(
        scenarios.map((item) =>
          item.id === tab ? { ...item, incomes } : item,
        ),
      );
    }
  };

  return (
    <Layout page="map" noPadding>
      <div className="pb-32 ">
        <div className={`sticky z-50 ${isFullscreen ? "top-0" : "top-[72px]"}`}>
          <div
            className={`flex sticky ${isFullscreen ? "top-[300px]" : "top-[70px]"} gap-2 z-[50000] bg-gray-100 py-5 w-full justify-center `}
          >
            <MapTab
              name="Income"
              active={mapTab === "income"}
              setActive={() => setMapTab("income")}
            />

            <MapTab
              name="Map"
              active={mapTab === "map"}
              setActive={() => setMapTab("map")}
            />
          </div>
          <Scenarios tab={tab} setTab={setTab} />

          {mapTab === "map" && <Map settings={settings} />}
          {mapTab === "income" && (
            <IncomeSection
              settings={settings}
              setSettings={setSettings}
              updateIncomes={updateIncomes}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Summary;
