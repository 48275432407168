import { Person, ScenarioSettings, StartEndTime } from "src/types";
import Select from "../Inputs/Select";
import { useInfo } from "src/hooks/useData";
import { splitDate } from "src/utils";
import Input from "../Inputs/Input";
import { NRA } from "src/calculator/utils";

export const startEndToYear = (
  value: StartEndTime,
  people: Person[],
  settings: ScenarioSettings,
): any => {
  let calendarYear = null;
  if (value.type === "calendar-year") {
    calendarYear = value.year;
  } else if (value.type === "age") {
    const { year } = splitDate(people[value.personId].birthday);
    calendarYear = value.age ? year + value.age : null;
  } else if (value.type === "death") {
    const { year } = splitDate(people[value.personId].birthday);
    const deathAge =
      (settings.whoDies || (settings as any).dead) === value.personId
        ? settings.deathYears[value.personId]
        : null;
    if (deathAge === null) return null;
    return deathAge + year;
  } else if (value.type === "retirement") {
    const { year } = splitDate(people[value.personId].birthday);
    const retirementAge = settings?.retirementYears[value.personId];
    if (retirementAge === null) return null;
    return retirementAge + year;
  } else if (value.type === "already-receiving") {
    calendarYear = null;
  } else if (value.type === "#-of-years") {
    calendarYear = new Date().getFullYear() + (value.years || 0);
  } else if (value.type === "fra") {
    const { year } = splitDate(people[0].birthday);
    const startYear = year + NRA(year)[0];
    calendarYear = startYear;
  }

  return calendarYear;
};

type Option =
  | ["calendar-year"]
  | ["age", number]
  | ["already-receiving"]
  | ["#-of-years"]
  | ["fra"]
  | ["death", number]
  | ["retirement", number];

interface Props {
  value: StartEndTime;
  setValue: (a: StartEndTime) => any;
  shownOptions: Option[];
  label: string;
  settings: ScenarioSettings;
}

const StartEndSelect = ({
  value,
  setValue,
  shownOptions,
  label,
  settings,
}: Props) => {
  if (!value) return null;
  const {
    data: { people },
  } = useInfo();

  const title = (v: StartEndTime) => {
    if (v.type === "calendar-year") return "Calendar Year";
    if (v.type === "age") return `${people[v.personId].name}'s Age`;
    if (v.type === "already-receiving") return "Already Receiving";
    if (v.type === "#-of-years") return "In # of Years";
    if (v.type === "death") return `${people[v.personId].name}'s Death`;
    if (v.type === "retirement")
      return `${people[v.personId].name}'s Retirement`;
    if (v.type === "fra") {
      const { year } = splitDate(people[0].birthday);
      const [y, m] = NRA(year);
      return `FRA (${y} years, ${m} months)`;
    }
  };

  const currentYear = startEndToYear(value, people, settings);

  const options = [
    shownOptions
      .filter((i) => i[0] === "already-receiving")
      .map(() => ({
        name: "Already Receiving",
        value: { type: "already-receiving" },
      })),
    shownOptions
      .filter((i) => i[0] === "calendar-year")
      .map(() => ({
        name: "Calendar Year",
        value: { type: "calendar-year", year: currentYear },
      })),
    shownOptions
      .filter((i) => i[0] === "fra")
      .map(() => ({
        name: title({ type: "fra" }),
        value: { type: "fra" },
      })),
    shownOptions
      .filter((i) => i[0] === "#-of-years")
      .map(() => [
        {
          name: title({
            type: "#-of-years",
            years: currentYear
              ? Math.max(currentYear - new Date().getFullYear(), 0)
              : (null as any),
          }),
          value: {
            type: "#-of-years",
            years: currentYear
              ? Math.max(currentYear - new Date().getFullYear(), 0)
              : (null as any),
          },
        },
      ]),

    shownOptions
      .filter((i) => i[0] === "age")
      .map(([_, personId]) => {
        const person = people[personId as any];
        return {
          name: `${person.name}'s Age`,
          value: {
            type: "age",
            personId: person.id,
            age: currentYear
              ? (currentYear as any) - splitDate(person.birthday).year
              : null,
          },
        };
      }),

    shownOptions
      .filter((i) => i[0] === "retirement")
      .map(([_, personId]) => {
        const person = people[personId as any];
        return {
          name: `${person.name}'s Retirement`,
          value: {
            type: "retirement",
            personId: person.id,
          },
        };
      }),
    shownOptions
      .filter((i) => i[0] === "death")
      .map(([_, personId]) => {
        const person = people[personId as any];
        return {
          name: `${person.name}'s Death`,
          value: {
            type: "death",
            personId: person.id,
          },
        };
      }),
  ].flat();
  const widths: any = {
    "calendar-year": "!w-[176px]",
    fra: "!w-64",
    age: "!w-[200px]",
    "#-of-years": "!w-[200px]",
    "already-receiving": "!w-64",
    retirement: "!w-64",
    death: "!w-64",
  };
  return (
    <>
      <div className="flex gap-1">
        <Select
          dropdownClass="!w-[255px]"
          options={options.flat()}
          selected={{ name: title(value), value }}
          setSelected={({ value }) => setValue(value)}
          label={label}
          width={widths[value.type]}
        />
        {value.type === "age" && (
          <Input
            label=""
            value={value.age}
            subtype="number"
            width="!w-12"
            setValue={(v) => setValue({ ...value, age: v })}
          />
        )}
        {value.type === "#-of-years" && (
          <Input
            label=""
            value={value.years}
            subtype="number"
            width="!w-12"
            setValue={(years) => setValue({ ...value, years })}
          />
        )}
        {value.type === "calendar-year" && (
          <Input
            label=""
            value={value.year}
            subtype="number"
            width="!w-[72px]"
            setValue={(v) => setValue({ ...value, year: v })}
          />
        )}
      </div>
    </>
  );
};

export default StartEndSelect;
