import {
  BasicAnnuity,
  CompanyPension,
  Income,
  IncomeType,
  Paydown,
  Person,
  SocialSecurityIncome,
} from "./types";

const id = () => Math.floor(Math.random() * 1000000);
export const newEmploymentIncome = (): any => ({
  id: id(),
  stable: false,
  enabled: true,
  type: "employment-income",
  personId: 0,
  annualIncome: null,
  startAge: null,
  firstYearProratePercent: 100,
  yearlyIncreasePercent: null,
  yearlyIncrease: { type: "general" },
  retirementAgeYear: null,
  retirementAgeMonth: null,
  taxType: "Taxable",
  start: { type: "already-receiving" },
  end: { type: "retirement", personId: 0 },
});

export const newSocialSecurityIncome = (
  person: Person,
): SocialSecurityIncome => ({
  id: id(),
  enabled: true,
  name: "",
  type: "social-security",
  personId: person.id,
  stable: true,
  annualAmount: 0,
  amount: { type: "yearly", value: null as any },
  cola: 0,
  yearlyIncrease: { type: "general" },
  pia: 0,
  calculationMethod: "pia",
  start: { type: "fra" },
  startAgeMonth: null as any,
  taxType: "Taxable",
});

export const newPensionIncome = (): CompanyPension => ({
  id: id(),
  enabled: true,
  stable: true,
  type: "company-pension",
  name: "",
  annualAmount: null as any,
  amount: null as any,
  start: { type: "already-receiving" },
  survivorPercent: 100,
  personId: 0,
  yearlyIncreasePercent: null as any,
  yearlyIncrease: { type: "none" },
  firstYearProRatePercent: 100,
  startAge: null,
  taxType: "Taxable",
});

export const newBasicAnnuity = (): BasicAnnuity => ({
  id: id(),
  enabled: true,
  type: "annuity",
  name: "",
  personId: 0,
  stable: true,
  annualAmount: null as any,
  amount: null as any,
  yearsOfDeferral: 0,
  yearlyIncreasePercent: null as any,
  yearlyIncrease: { type: "none" },
  survivorPercent: null as any,
  firstYearProRatePercent: 100,
  taxType: "Taxable",
});

export const otherIncome = (): any => ({
  id: id(),
  enabled: true,
  type: "other-income",
  personId: 0,
  stable: false,
  startYear: null as any,
  amount: null as any,
  endYear: null as any,
  start: { type: "already-receiving" },
  end: { type: "already-receiving" },
  frequency: "annually",
  yearlyIncreasePercent: null as any,
  yearlyIncrease: { type: "general" },
  survivorPercent: 100,
  firstYearProRatePercent: 100,
  name: "",
  taxType: "Taxable",
});

export const paydown = (): Paydown => ({
  id: id(),
  enabled: true,
  type: "paydown",
  personId: 0,
  name: "",
  stable: false,
  start: { type: "already-receiving" },
  length: null as any,
  paymentInYear: "beginning",
  interestRate: null as any,
  total: null as any,
  taxType: "Taxable",
});

export const newIncome = (type: IncomeType, person: Person): Income => {
  switch (type) {
    case "employment-income":
      return newEmploymentIncome();
    case "social-security":
      return newSocialSecurityIncome(person);
    case "company-pension":
      return newPensionIncome();
    case "annuity":
      return newBasicAnnuity();
    case "other-income":
      return otherIncome();
    case "paydown":
      return paydown();
  }
};
