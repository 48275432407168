import Select from "../Inputs/Select";
import Input from "../Inputs/Input";
import IncomeYearlyIncrease from "./IncomeYearlyIncrease";
import {
  Person,
  CompanyPension as CompanyPensionType,
  ScenarioSettings,
} from "src/types";
import StartEndSelect from "./StartEndSelect";

interface Props {
  pension: CompanyPensionType;
  people: Person[];
  setIncome: (income: CompanyPensionType) => void;
  settings: ScenarioSettings;
}

const CompanyPension = ({ people, pension, setIncome, settings }: Props) => {
  const amount = pension.amount
    ? pension.amount
    : {
      type: "yearly",
      value: pension.annualAmount,
    };
  return (
    <>
      <div className="flex-grow">
        <div className="flex flex-col gap-4 ">
          {people.length > 1 && (
            <Select
              options={[...people]}
              selected={people[pension.personId]}
              width="!w-64"
              setSelected={(i) => setIncome({ ...pension, personId: i.id })}
              label="Person"
            />
          )}
          <Input
            label="Title"
            subtype="text"
            size="lg"
            width="!w-64"
            value={pension.name}
            setValue={(name) => setIncome({ ...pension, name })}
          />

          <Input
            label="Amount"
            subtype="mo/yr"
            size="lg"
            width="!w-64"
            value={amount}
            setValue={(name) => setIncome({ ...pension, amount: name })}
          />
          <IncomeYearlyIncrease
            labels={false}
            increase={
              pension.yearlyIncrease || {
                type: "custom",
                percent: pension.yearlyIncreasePercent,
              }
            }
            setYearlyIncrease={(yearlyIncrease) =>
              setIncome({ ...pension, yearlyIncrease })
            }
          />
          {people.length > 1 && (
            <Input
              label="Survivor %"
              subtype="percent"
              size="lg"
              width="!w-64"
              value={pension.survivorPercent}
              setValue={(name) =>
                setIncome({ ...pension, survivorPercent: name })
              }
            />
          )}

          <StartEndSelect
            settings={settings}
            label="Start"
            shownOptions={[
              ["already-receiving"],
              ["age", pension.personId],
              ["calendar-year"],
              ["retirement", pension.personId],
            ]}
            value={pension.start}
            setValue={(start) => setIncome({ ...pension, start })}
          />
          <Input
            label="First-Year Prorate"
            subtype="percent"
            size="lg"
            width="!w-64"
            value={pension.firstYearProRatePercent}
            tooltip="What percentage of the annuity will be paid in the first year?"
            setValue={(name) =>
              setIncome({
                ...pension,
                firstYearProRatePercent: parseInt(name),
              })
            }
          />
          <Select
            options={[
              { name: "Taxable", id: "Taxable" },
              { name: "Tax-Deferred", id: "Tax-Deferred" },
              { name: "Tax-Free", id: "Tax-Free" },
            ]}
            selected={{
              name: pension.taxType,
              id: pension.taxType,
            }}
            setSelected={(i) => setIncome({ ...pension, taxType: i.id })}
            label="Tax Status"
            width="!w-64"
          />
        </div>
      </div>
    </>
  );
};

export default CompanyPension;
