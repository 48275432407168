import Select from "../Inputs/Select";
import Input from "../Inputs/Input";
import MonthPicker from "../Inputs/MonthPicker";
import IncomeYearlyIncrease from "./IncomeYearlyIncrease";
import { Person, EmploymentIncome, ScenarioSettings } from "src/types";
import StartEndSelect from "./StartEndSelect";

interface Props {
  income: EmploymentIncome;
  people: Person[];
  setIncome: (income: EmploymentIncome) => void;
  settings: ScenarioSettings;
}

const EmploymentIncomeComponent = ({
  people,
  income,
  setIncome,
  settings,
}: Props) => {
  const amount = income.income
    ? income.income
    : {
        type: "yearly",
        value: income.annualIncome,
      };
  return (
    <div className="flex flex-col gap-4">
      {people.length > 1 && (
        <Select
          label="Person"
          width="w-64"
          options={[...people]}
          selected={people[income.personId]}
          setSelected={(i) => setIncome({ ...income, personId: i.id })}
        />
      )}
      <Input
        label="Title"
        subtype="text"
        size="lg"
        width="!w-64"
        value={income.name}
        setValue={(name) => setIncome({ ...income, name })}
      />
      <Input
        label="Income"
        subtype="mo/yr"
        size="lg"
        width="!w-64"
        value={amount}
        setValue={(name) => setIncome({ ...income, income: name })}
      />
      <IncomeYearlyIncrease
        labels={false}
        increase={
          income.yearlyIncrease || {
            type: "custom",
            percent: income.yearlyIncreasePercent,
          }
        }
        setYearlyIncrease={(yearlyIncrease) =>
          setIncome({ ...income, yearlyIncrease })
        }
      />
      <StartEndSelect
        label="Start"
        shownOptions={[
          ["already-receiving"],
          ["age", income.personId],
          ["calendar-year"],
          ["#-of-years"],
        ]}
        value={income.start}
        setValue={(start) => setIncome({ ...income, start })}
        settings={settings}
      />
      {/*<Input
        label="Start Age"
        subtype="number"
        width="!w-64"
        size="lg"
        value={income.startAge}
        invalid={!!income.startAge && income.startAge > 100}
        errorMessage="Start Age should be the age of the person, not the calendar year"
        setValue={(name) => setIncome({ ...income, startAge: name })}
        tooltip="Leave blank if already receiving"
      />*/}
      <Input
        label="First-Year Prorate"
        subtype="percent"
        size="income"
        width="!w-64"
        value={income.firstYearProratePercent}
        setValue={(name) =>
          setIncome({ ...income, firstYearProratePercent: name })
        }
        tooltip="The percentage of the first year's income that is earned."
      />

      <StartEndSelect
        label="End"
        shownOptions={[
          ["death", income.personId],
          ["age", income.personId],
          ["calendar-year"],
          ["#-of-years"],
          ["retirement", income.personId],
        ]}
        value={income.end}
        setValue={(end) => setIncome({ ...income, end })}
        settings={settings}
      />
      <MonthPicker
        label="End Month"
        selected={income.retirementAgeMonth}
        setSelected={(i) => setIncome({ ...income, retirementAgeMonth: i.id })}
      />
      <Select
        options={[
          { name: "Taxable", id: "Taxable" },
          { name: "Tax-Deferred", id: "Tax-Deferred" },
          { name: "Tax-Free", id: "Tax-Free" },
        ]}
        selected={{
          name: income.taxType,
          id: income.taxType,
        }}
        setSelected={(i) => setIncome({ ...income, taxType: i.id })}
        label="Tax Status"
        width="!w-64"
      />
    </div>
  );
};

export default EmploymentIncomeComponent;
