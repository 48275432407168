import WhoDies from "../../WhoDies";
import { updateAtIndex } from "../../../utils";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Input from "src/components/Inputs/Input";
import { useFullscreen } from "src/hooks/useFullScreen";
import { useInfo } from "src/hooks/useData";
import { Client, ScenarioSettings } from "src/types";
import title from "src/calculator/title";
import Select from "../../Inputs/Select";
import Taxes from "./Taxes";
import MultiToggle from "src/components/Inputs/MultiToggle";

interface Props {
  settings: ScenarioSettings;
  client: Client;
}

const Spacer = () => (
  <div className="bg-gray-300 mt-[-5px] w-[1px] h-[80px] mx-2"></div>
);

const ScenarioHeader = ({ client, settings }: Props) => {
  const { data, setField, storeScenarios } = useInfo();

  const setSettings = (v: any) => {
    if (settings.id === -1) {
      setField("liveSettings")({ ...client.liveSettings, ...v });
    } else {
      storeScenarios(
        data.scenarios.map((item) =>
          item.id === settings.id ? { ...item, ...v } : item,
        ),
      );
    }
  };
  const setLiveSettings = (v: any) => {
    setField("liveSettings")({ ...client.liveSettings, ...v });
  };

  const { isFullscreen } = useFullscreen();
  const disabled = false; // settings.id !== -1;

  return (
    <div
      className={`flex justify-between items-center sticky ${isFullscreen ? "top-[44px]" : "top-[196px]"} z-[5000] bg-white px-4`}
    >
      <div className="flex gap-3 z-0">
        {settings.people.length == 2 && (
          <>
            <div className="flex flex-col gap-1 py-2">
              <div className="text-gray-500 text-[14px] font-semibold">
                Mortality
              </div>
              <div className="flex ">
                <WhoDies
                  active={settings.whoDies == -1}
                  setWhoDies={(i: number) => setSettings({ whoDies: i })}
                  i={-1}
                  title="Both Alive"
                />
                {settings.people.map((person, i) => (
                  <WhoDies
                    active={settings.whoDies == i}
                    key={person.id}
                    age={settings.deathYears[i]}
                    setAge={(e: any) =>
                      setSettings({
                        deathYears: updateAtIndex(
                          settings.deathYears,
                          i,
                          parseInt(e),
                        ),
                      })
                    }
                    setWhoDies={(i: number) => setSettings({ whoDies: i })}
                    i={i}
                    title={`${person.name}`}
                  />
                ))}
              </div>
            </div>
            <Spacer />
          </>
        )}
        <div className="flex flex-col gap-1 py-2">
          <div className="text-gray-500 text-[14px] font-semibold mt-[-3px]">
            Retirement Age
          </div>

          <div className="flex gap-3 mt-[-4px]">
            {client.people.map((p) => (
              <Input
                inlineLabel={`${p.name}`}
                label=""
                subtype="number"
                vertical
                key={`retirement-${settings.id}-${p.id}`}
                width="!w-[120px] !py-[4px]"
                value={settings.retirementYears[p.id]}
                setValue={(v) => {
                  console.log("rett", settings.retirementYears, p.id, v);
                  setSettings({
                    retirementYears: settings.retirementYears.map((r, i) =>
                      i === p.id ? v : r,
                    ),
                  });
                }}
              />
            ))}
          </div>
        </div>

        <Spacer />
        <div className="flex flex-col py-2">
          <div className="text-gray-500 text-[14px] font-semibold">
            Inflation
          </div>
          <div className="flex justify-center gap-3 items-center">
            <div className="">
              <MultiToggle
                vertical={true}
                options={["Real", "Nominal"]}
                label=""
                value={settings.inflationType}
                setValue={(v: any) => setLiveSettings({ inflationType: v })}
              />
            </div>
            <div className="">
              <Input
                onFocus={(event: any) => {
                  const input = event.target;
                  setTimeout(() => {
                    input.select();
                  }, 0);
                }}
                label=""
                labelLength={45}
                size="xs"
                disabled={disabled}
                vertical
                width="!w-[80px] !py-[4px]"
                subtype="percent"
                value={settings.inflation}
                setValue={(e) => setSettings({ inflation: e })}
              />
            </div>
          </div>
        </div>

        <Spacer />
        {client.taxesFlag && (
          <>
            <Taxes settings={settings} />
            <Spacer />
          </>
        )}
      </div>
      <div className="flex flex-col py-2">
        <div className="text-gray-500 text-[14px] font-semibold">
          Display settings
        </div>

        <div className="flex gap-2">
          <div className="!text-[14px]">
            <Select
              small
              options={[
                "all incomes",
                "composite",
                "by tax status",
                "by income type",
                "by person",
              ].map((name) => ({
                name: name
                  .split(" ")
                  .map((s) => s[0].toUpperCase() + s.slice(1))
                  .join(" "),
                id: name,
              }))}
              width="!w-[150px]"
              selected={{
                name: client.liveSettings.mapType
                  ?.split(" ")
                  .map((s) => s[0].toUpperCase() + s.slice(1))
                  .join(" "),
                id: client.liveSettings.mapType,
              }}
              setSelected={(mapType) =>
                setField("liveSettings")({
                  ...client.liveSettings,
                  mapType: mapType.id,
                })
              }
              label=""
            />
          </div>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="bg-[#EDEEF1] w-8 h-8 flex items-center justify-center cursor-pointer rounded-md ">
                <img src="/icons/more.png" alt="" className="w-4" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="p-3 text-[#9396A0] text-[14px]">
                  Table configurations
                </div>
                <div className="bg-gray-500 h-[1px] w-full"></div>
                <div className="p-3">
                  <Input
                    label="Years Shown"
                    value={settings.maxYearsShown}
                    setValue={(v: any) =>
                      setLiveSettings({ ...settings, maxYearsShown: v })
                    }
                    tooltip={
                      <div className="w-40">
                        How many years to show in table and on graphs
                      </div>
                    }
                    labelLength={100}
                    width="w-[100px]"
                    subtype="number"
                    size="md"
                  />
                  <Input
                    subtype="toggle"
                    width="mt-2"
                    label={"Show tax status"}
                    value={settings.showTaxType}
                    setValue={(v) =>
                      setLiveSettings({ ...settings, showTaxType: v })
                    }
                  />

                  <div className="mt-3">
                    <MultiToggle
                      options={["Monthly", "Annual"]}
                      label=""
                      vertical={true}
                      value={
                        client.liveSettings.monthlyYearly === "monthly"
                          ? "Monthly"
                          : "Annual"
                      }
                      setValue={(v: any) =>
                        setField("liveSettings")({
                          ...client.liveSettings,
                          monthlyYearly: v.toLowerCase(),
                        })
                      }
                    />
                  </div>
                </div>
                <>
                  <div className="p-3 text-[#9396A0] text-[14px]">
                    Toggle incomes on/off
                  </div>
                  <div className="bg-gray-500 h-[1px] w-full"></div>
                  <div className="flex flex-col px-3 max-h-[350px] overflow-y-auto">
                    {settings.incomes.map((income, i) => (
                      <div className="flex items-center">
                        <div className="text-xs w-[555px]">
                          {title(client.incomes, client.people, i)}
                        </div>
                        <Input
                          subtype="toggle"
                          width="!text-[10px]"
                          disabled={disabled}
                          label={""}
                          value={income.enabled}
                          setValue={(v) =>
                            setField("incomes")(
                              client.incomes.map((income, j) =>
                                j == i ? { ...income, enabled: v } : income,
                              ),
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                </>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default ScenarioHeader;
