import { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useInfo } from "src/hooks/useData";

interface NotesEditorProps {
  initialContent?: string;
  onSave?: (content: string) => void;
}

const NotesEditor = ({ initialContent = "", onSave }: NotesEditorProps) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);
  const [content, setContent] = useState(initialContent);
  const { setField } = useInfo();

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      const quill = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["link"],
            ["clean"],
          ],
        },
        placeholder: "Add notes here...",
      });

      if (initialContent) {
        quill.clipboard.dangerouslyPasteHTML(initialContent);
      }

      quill.on("text-change", () => {
        const html = quill.root.innerHTML;
        setContent(html);
      });

      quillRef.current = quill;
    }

    return () => {
      quillRef.current = null;
    };
  }, [editorRef, initialContent]);

  const handleSave = () => {
    if (onSave) {
      setField("notes")(content);
      onSave(content);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow">
        <div ref={editorRef} className="h-[400px] w-[800px]"></div>
      </div>
      <div className="flex justify-center mt-4 w-full ">
        <button
          className="bg-main-orange text-white px-4 py-2 rounded-md hover:bg-orange-600"
          onClick={handleSave}
        >
          Save Notes
        </button>
      </div>
    </div>
  );
};

export default NotesEditor;
