import CompanyPension from "./Info/CompanyPension";
import BasicAnnuity from "./Info/BasicAnnuity";
import Paydown from "./Info/Paydown";
import OtherIncome from "./Info/OtherIncome";
import SocialSecurity from "./Info/SocialSecurity";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline";
import Confirm from "./Confirm";
import { useState } from "react";
import title from "../calculator/title";
import Toggle from "./Inputs/Toggle";
import AddIncome from "./Info/AddIncome";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import SortableItem from "./Sortable/SortableItem";
import { useInfo } from "../hooks/useData";
import Input from "./Inputs/Input";
import {
  EmploymentIncome,
  Income,
  Person,
  ScenarioSettings,
  SocialSecurityIncome,
} from "src/types";
import EmploymentIncomeComponent from "./Info/EmploymentIncome";
import { updateAtIndex } from "src/utils";

export const IncomeComponent = ({
  income,
  people,
  setIncome,
  settings,
  i,
}: {
  income: Income;
  people: Person[];
  i: number;
  setIncome: any;
  settings: ScenarioSettings;
}) => {
  if (income.type === "employment-income")
    return (
      <EmploymentIncomeComponent
        key={i}
        people={people}
        income={income as EmploymentIncome}
        setIncome={(income) => setIncome(i, income)}
        settings={settings}
      />
    );
  else if (income.type === "social-security")
    return (
      <SocialSecurity
        settings={settings}
        key={i}
        people={people}
        income={income as SocialSecurityIncome}
        setIncome={(income) => setIncome(i, income)}
      />
    );
  else if (income.type === "annuity")
    return (
      <BasicAnnuity
        key={i}
        people={people}
        annuity={income as any}
        setIncome={(income) => setIncome(i, income)}
      />
    );
  else if (income.type === "other-income")
    return (
      <OtherIncome
        settings={settings}
        key={i}
        people={people}
        annuity={income as any}
        setIncome={(income) => setIncome(i, income)}
      />
    );
  else if (income.type === "paydown")
    return (
      <Paydown
        settings={settings}
        key={i}
        people={people}
        paydown={income as any}
        setIncome={(income) => setIncome(i, income)}
      />
    );
  else if (income.type === "company-pension")
    return (
      <CompanyPension
        settings={settings}
        key={i}
        people={people}
        pension={income as any}
        setIncome={(income) => setIncome(i, income)}
      />
    );
};

const IncomeSection = ({
  settings,
  updateIncomes,
  setSettings,
}: {
  settings: ScenarioSettings;
  setSettings: any;
  updateIncomes: any;
}) => {
  const [removeOpen, setRemoveOpen] = useState(-1);
  const { data } = useInfo();
  const incomes = settings.incomes;
  const people = data.people;

  const removeIncome = (i: number) =>
    updateIncomes(incomes.filter((_, index) => index !== i));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const activeId = active.id.split("-")[1];
    const overId = over.id.split("-")[1];
    const oldIndex = incomes.findIndex((s) => s.id === activeId);
    const newIndex = incomes.findIndex((s) => s.id === overId);
    if (oldIndex !== newIndex) {
      updateIncomes(arrayMove([...incomes], oldIndex, newIndex));
    }
  };
  return (
    <div>
      <div className="flex gap-6 items-center justify-between py-6 sticky z-50 top-[198px] bg-[#f3f4f6] px-[10px] ml-[-10px] w-[1428px] mt-5">
        <div className="font-semibold text-2xl"></div>
        <div className=" flex gap-3 h-10 items-end">
          <div className="w-[140px]">
            <Input
              label="General Inflation"
              vertical
              value={settings.inflation}
              subtype="percent"
              width="!w-[120px]"
              setValue={(i) =>
                setSettings({
                  inflation: i,
                })
              }
            />
          </div>
          <div className="mr-4">
            <AddIncome
              incomes={incomes}
              addIncome={(i: Income) => updateIncomes([...incomes, i])}
            />
          </div>
        </div>
      </div>
      {incomes.length === 0 && (
        <div className="w-full h-[calc(100vh-300px)] flex justify-center items-center text-gray-500 text-xl">
          This client has no incomes yet. Add an income by pressing the button
          on top right.
        </div>
      )}
      <div className="grid  md:grid-cols-2 lg:grid-cols-3 gap-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={incomes} strategy={rectSortingStrategy}>
            {incomes.map((item, i) => (
              <SortableItem
                key={`${settings.id}-${item.id}`}
                id={`${settings.id}-${item.id}`}
              >
                <div
                  style={item.enabled ? {} : { filter: "opacity(50%)" }}
                  className="shadow-md h-full rounded-xl bg-white"
                >
                  <div className="p-6 flex-grow">
                    <div className="flex items-center mb-6 pb-4  border-b">
                      <div className="flex justify-start cursor-pointer mr-2">
                        <EllipsisVerticalIcon className="text-slate-800 w-5 mr-[-15px]" />
                        <EllipsisVerticalIcon className="text-slate-800 w-5 mr-[-15px]" />
                        <EllipsisVerticalIcon className="text-slate-800 w-5 " />
                      </div>
                      <div className="font-semibold text  flex justify-between items-center flex-grow">
                        <div>{title(incomes, people, i)}</div>
                        <div className="flex items-center gap-3">
                          <Toggle
                            enabled={item.enabled}
                            setEnabled={(enabled) =>
                              updateIncomes(
                                incomes.map((item, index) =>
                                  index === i ? { ...item, enabled } : item,
                                ),
                              )
                            }
                          />
                          <div
                            className="bg-[rgba(240,82,82,0.1)] p-3 rounded-full cursor-pointer"
                            onClick={() => setRemoveOpen(i)}
                          >
                            <TrashIcon className="text-red-500 w-5" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="">
                        <IncomeComponent
                          settings={settings}
                          income={item}
                          people={data.people}
                          i={i}
                          setIncome={(i: any, income: any) =>
                            updateIncomes(updateAtIndex(incomes, i, income))
                          }
                        />
                        {data.stabilityRatioFlag && (
                          <div className="mt-3">
                            <Input
                              label="Stable Income"
                              subtype="toggle"
                              size="lg"
                              value={item.stable}
                              setValue={(stable) =>
                                updateIncomes(
                                  incomes.map((item, index) =>
                                    index === i ? { ...item, stable } : item,
                                  ),
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                      <Confirm
                        isOpen={removeOpen == i}
                        onClose={() => setRemoveOpen(-1)}
                        onConfirm={() => {
                          removeIncome(i);
                          setRemoveOpen(-1);
                        }}
                      >
                        <TrashIcon className="text-slate-400 w-10 m-auto mb-5" />
                        <div className="mb-5">
                          Are you sure you want to delete this income?
                        </div>
                      </Confirm>
                    </div>
                  </div>
                </div>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default IncomeSection;
