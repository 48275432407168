import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Input from "src/components/Inputs/Input";
import MultiToggle from "src/components/Inputs/MultiToggle";
import { useInfo } from "src/hooks/useData";
import { ScenarioSettings } from "src/types";
interface Props {
  settings: ScenarioSettings;
}

const Taxes = ({ settings }: Props) => {
  const setLiveSettings = (v: any) => {
    setField("liveSettings")({ ...client.liveSettings, ...v });
  };
  const { data: client, setField } = useInfo();
  return (
    <div className="flex flex-col gap-1 py-2">
      <div className="text-gray-500 text-[14px] font-semibold">Taxes</div>
      <div className="flex justify-center gap-2">
        <div className="w-28 mt-[-3px]">
          <MultiToggle
            options={["Pre", "Post"]}
            label=""
            vertical={true}
            value={settings.taxType}
            setValue={(v: any) => setLiveSettings({ ...settings, taxType: v })}
          />
        </div>

        {client.taxesFlag && (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="bg-[#EDEEF1] w-8 h-8 flex items-center justify-center cursor-pointer rounded-md mt-[0px]">
                <img src="/icons/tripledot.png" alt="" className="w-4" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="flex flex-col gap-3 p-3">
                  <Input
                    subtype="percent"
                    label="Pre-Retirement Tax Rate (%)"
                    value={client.spending.preTaxRate}
                    setValue={(preTaxRate) =>
                      setField("spending")({
                        ...client.spending,
                        preTaxRate,
                      })
                    }
                    vertical
                  />
                  <Input
                    subtype="percent"
                    label="Post-Retirement Tax Rate (%)"
                    value={client.spending.postTaxRate}
                    setValue={(postTaxRate) =>
                      setField("spending")({
                        ...client.spending,
                        postTaxRate,
                      })
                    }
                    vertical
                  />
                  <Input
                    subtype="number"
                    label="Retirement Year"
                    value={client.liveSettings.retirementYear}
                    setValue={(retirementYear) =>
                      setField("liveSettings")({
                        ...client.liveSettings,
                        retirementYear,
                      })
                    }
                    vertical
                  />
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
    </div>
  );
};

export default Taxes;
