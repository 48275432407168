import Select from "../Inputs/Select";
import Input from "../Inputs/Input";
import MonthPicker from "../Inputs/MonthPicker";
import IncomeYearlyIncrease from "./IncomeYearlyIncrease";
import { Person, ScenarioSettings, SocialSecurityIncome } from "src/types";
import StartEndSelect from "./StartEndSelect";

interface Props {
  income: SocialSecurityIncome;
  people: Person[];
  setIncome: (income: SocialSecurityIncome) => void;
  settings: ScenarioSettings;
}

const SocialSecurity = ({
  people,
  income: pension,
  setIncome,
  settings,
}: Props) => {
  // const canRecieve =
  //   calculateAge(new Date(people[pension.personId].birthday)) >= 62;

  const handleStartAgeMonthChange = (month: { id: number }) => {
    setIncome({ ...pension, startAgeMonth: month.id || 0 }); // Set to 1 if blank or 0
  };

  const amount = pension.amount
    ? pension.amount
    : { value: pension.annualAmount, type: "yearly" };

  // const overage = monthsToFullRetirement(
  //   people[pension.personId].birthday,
  //   pension.startAgeYear,
  //   pension.startAgeMonth,
  // );
  // const invalid = overage < -60 && !!pension.startAgeYear;
  return (
    <>
      <div className="flex-grow">
        <div className="flex flex-col gap-4">
          <div className="flex gap-4 items-center justify-between">
            <div className="flex flex-col gap-4 justify-start">
              {people.length > 1 && (
                <Select
                  options={people}
                  selected={people[pension.personId]}
                  width="!w-64"
                  setSelected={(i) => setIncome({ ...pension, personId: i.id })}
                  label="Person"
                />
              )}
              <Input
                label="Title"
                subtype="text"
                size="lg"
                width="!w-64"
                value={pension.name}
                setValue={(name) => setIncome({ ...pension, name })}
              />
              <Select
                options={[
                  { name: "Manual", id: "manual" },
                  { name: "PIA", id: "pia" },
                ]}
                width="!w-64"
                selected={{
                  name: pension.calculationMethod === "pia" ? "PIA" : "Manual",
                  id: pension.calculationMethod,
                }}
                setSelected={(i) => {
                  const start =
                    pension.start.type === "already-receiving" &&
                      pension.calculationMethod === "manual" &&
                      i.id === "pia"
                      ? { type: "fra" as const }
                      : pension.start;
                  setIncome({ ...pension, calculationMethod: i.id, start });
                }}
                label="Calculation Method"
              />
              {pension.calculationMethod == "pia" && (
                <Input
                  label="Monthly PIA"
                  subtype="money"
                  width="!w-64"
                  size="income"
                  value={pension.pia}
                  setValue={(name) => setIncome({ ...pension, pia: name })}
                  tooltip="Primary Insurance Amount"
                />
              )}
              {pension.calculationMethod == "manual" && (
                <Input
                  label="Amount"
                  subtype="mo/yr"
                  width="!w-64"
                  size="lg"
                  value={amount}
                  setValue={(name) => setIncome({ ...pension, amount: name })}
                />
              )}
              <IncomeYearlyIncrease
                labels={false}
                customLabel="COLA"
                increase={
                  pension.yearlyIncrease || {
                    type: "custom",
                    percent: pension.cola,
                  }
                }
                setYearlyIncrease={(yearlyIncrease) =>
                  setIncome({ ...pension, yearlyIncrease })
                }
              />

              <StartEndSelect
                label="Start"
                shownOptions={[
                  pension.calculationMethod == "manual"
                    ? (["already-receiving"] as const)
                    : ([] as any),
                  ["age", pension.personId],
                  ["calendar-year"],
                  ["fra"],
                  ["retirement", pension.personId],
                ]}
                value={pension.start}
                settings={settings}
                setValue={(start) => setIncome({ ...pension, start })}
              />

              {pension.start.type !== "already-receiving" &&
                pension.start.type !== "fra" && (
                  <MonthPicker
                    label="Start Month"
                    errorMessage={
                      <div className="w-32">
                        Start month must be after{" "}
                        {people[pension.personId].name}
                        's 62nd birthday.
                      </div>
                    }
                    selected={pension.startAgeMonth} // Use 1 if startAgeMonth is 0 or undefined
                    setSelected={handleStartAgeMonthChange}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialSecurity;
